import { Input } from "antd"
import { DefaultOptionType } from "antd/es/select"
import api from "helpers/api"
import { useEffect, useState } from "react"
import { ValueEditorProps } from "react-querybuilder"
import { AsyncPaginate as AsyncSelect } from "react-select-async-paginate"
import { inputOperators } from "./types"

type Props = ValueEditorProps & { optionUrl: string; requestData?: any }

const SelectSelector = (props: Props) => {
    const { value, handleOnChange, field, optionUrl, requestData } = props

    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<any[]>([])

    const loadedOptions = async (
        search: string,
        _: any,
        additional: { page: number; initial?: boolean } | undefined
    ) => {
        const page = additional?.page ?? 1
        setLoading(true)
        try {
            const response = await api.getTyped<Record<string, string>>(
                optionUrl,
                {
                    attribute: field,
                    query: search,
                    page: page,
                    count_on_page: 200,
                    id: additional?.initial ? value : undefined,
                }
            )
            const options = Object.entries(response).map(
                ([key, value]) =>
                    ({
                        label: value,
                        value: key,
                    } as DefaultOptionType)
            )
            setOptions((prev) => [...prev, ...options])
            return {
                options: options,
                hasMore: options.length >= 200,
                additional: {
                    page: page + 1,
                },
            }
        } catch (err: any) {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: 1,
                },
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        loadedOptions("", undefined, { page: 1, initial: true })
    }, [])

    return (
        <AsyncSelect
            styles={{
                valueContainer: (provided) => ({
                    ...provided,
                    height: "32px",
                    padding: "0 6px",
                }),
                input: (provided) => ({
                    ...provided,
                    height: "32px",
                    margin: 0,
                }),
                indicatorSeparator(base, props) {
                    return {
                        ...base,
                        height: "100%",
                        marginTop: 0,
                    }
                },
                indicatorsContainer: (base) => ({
                    ...base,
                    height: "100%",
                }),
                control: (provided) => ({
                    ...provided,
                    height: "32px",
                    minHeight: "32px",
                }),
                container: (base) => ({
                    ...base,
                    height: "32px",
                }),
            }}
            key={field}
            loadOptionsOnMenuOpen
            className="fw"
            debounceTimeout={500}
            placeholder="Выберите..."
            loadingMessage={({ inputValue }) => {
                console.log(inputValue)
                return (
                    <span>
                        {inputValue ? `Поиск : "${inputValue}"` : "Загрузка..."}
                    </span>
                )
            }}
            value={value ? options.find((el) => el.value === value) : undefined}
            reduceOptions={(prevOption, loadedOptions, additional) => {
                return [...prevOption, ...loadedOptions]
            }}
            loadOptions={loadedOptions}
            isLoading={loading}
            onChange={(e: any) => {
                handleOnChange(e?.value)
            }}
            isSearchable
            pageSize={200}
        />
    )
}

export const InputSelector = (props: Props) => {
    const { value, handleOnChange } = props

    return (
        <Input value={value} onChange={(e) => handleOnChange(e.target.value)} />
    )
}

function ValuesAsyncSelector(props: Props) {
    const { operator } = props

    if (inputOperators.includes(operator)) return <InputSelector {...props} />

    return <SelectSelector {...props} />
}

export default ValuesAsyncSelector
