import { useQuery } from "@tanstack/react-query"
import { Form } from "antd"
import Select, { DefaultOptionType } from "antd/es/select"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"

export type Rounding = 0.01 | 0.1 | 0 | 10 | 100

export const roundingOptions: DefaultOptionType[] = [
    {
        label: "0,01",
        value: 2,
    },
    {
        label: '0,1',
        value: 1,
    },
    {
        label: 0,
        value: 0,
    },
    {
        label: 10,
        value: -1,
    },
    {
        label: 100,
        value: -2,
    },
]

type RoundingMethod = "floor" | "ceil" | "round"

export const getRoundingMethodLabel = (method: RoundingMethod) => {
    switch (method) {
        case "ceil":
            return "Всегда в большую"
        case "floor":
            return "Всегда в меньшую"
        case "round":
            return "Округление"
        default:
            return method
    }
}

export default function CalculationRulesPrecision() {
    const { data, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: ["price-calculation-rule-rounding"],
        queryFn: () =>
            api
                .getTyped<RoundingMethod[]>(
                    "v1/price-calculation-rule/rounding"
                )
                .then((data = []) =>
                    data.map((el) => ({
                        label: getRoundingMethodLabel(el),
                        value: el,
                    }))
                ),
    })

    return (
        <Form.Item label="Округление">
            <Flex.Row gap={20} className="--visible" justify="start">
                <Flex.Row gap={5}>
                    <Form.Item
                        name="decimals"
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: true,
                                message: "Заполните поле",
                            },
                        ]}
                    >
                        <Select
                            options={roundingOptions}
                            style={{ minWidth: 100 }}
                            size="large"
                        />
                    </Form.Item>
                </Flex.Row>

                <Flex.Row gap={5}>
                    <Form.Item
                        name="roundingStrategy"
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: true,
                                message: "Заполните поле",
                            },
                        ]}
                    >
                        <Select
                            loading={isFetching}
                            options={data}
                            style={{ minWidth: 300 }}
                            size="large"
                        />
                    </Form.Item>
                </Flex.Row>
            </Flex.Row>
        </Form.Item>
    )
}
