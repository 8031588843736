import { useQuery } from "@tanstack/react-query"
import { Col, InputNumber, Row } from "antd"
import { GroupSelect } from "components/Selects/newSelects/GroupSelect"
import { RegionSelect } from "components/Selects/newSelects/RegionSelect"
import { SelectEntity } from "components/Selects/types"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { useDetailData } from "hooks/useDetailData"
import { useNotifications } from "hooks/useNotifications"
import { useRef, useState } from "react"
import { AttributeListItem, Uuid } from "types"
import { Attribute } from "types/api"
import SeriesDetailCombinationOrder from "./SeriesDetailCombinationOrder"

export default function SeriesDetailPriceSet() {
    const [activGroup, setActiveGroup] = useState<SelectEntity | null>(null)
    const [activeRegion, setActiveRegion] = useState<SelectEntity | null>(null)
    const [selectedRow, setSelectedRow] = useState<Attribute | null>(null)

    const { showNotification } = useNotifications()

    const { id } = useDetailData()

    const showTable = !!activGroup?.value && !!activeRegion?.value

    const { data, isFetching, dataUpdatedAt } = useQuery<
        AttributeListItem["attribute"][]
    >({
        queryKey: [activGroup, activeRegion, id],
        enabled: !!activGroup && !!activeRegion,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api
                .getTyped<AttributeListItem[]>(
                    `v1/series/${id}/items-group/${activGroup?.value}/attribute-links`
                )
                .then((data) => data.map((el) => el.attribute)),
        initialData: [],
    })

    const {
        data: existingOrders,
        refetch: refetchExistingOrders,
        dataUpdatedAt: existingOrdersUpdatedAt,
    } = useQuery({
        queryKey: ["existing-links", id, activGroup, activeRegion],
        enabled: !!activGroup?.value && !!activeRegion?.value,
        queryFn: () =>
            api.getTyped<any[]>(`v1/economics/series/attribute-order`, {
                series: id,
                region: activeRegion?.value,
                group: activGroup?.value,
            }),
        initialData: [],
    })

    const handleSaveOrder = (attribute: Uuid, value: number) => {
        const method = (value ? api.put : api.post).bind(api)
        const deletPart = value ? "" : "/delete"
        method(
            "v1/economics/series/attribute-order" + deletPart,
            {},
            {
                series: id,
                region: activeRegion?.value,
                group: activGroup?.value,
                attribute,
                value: value || undefined,
            }
        ).then(() => {
            refetchExistingOrders()
            showNotification({
                message: "Сохранено",
                type: "success",
            })
        })
    }

    const focusRefValue = useRef<string>("")

    return (
        <Flex.Col gap={20} fullWidth>
            <RegionSelect
                inputSpan={18}
                labelSpan={6}
                activeRegion={activeRegion}
                setActiveRegion={setActiveRegion}
                size="large"
                formLabelFontSize={16}
                fullWidth
            />

            <GroupSelect
                inputSpan={18}
                labelSpan={6}
                activeGroup={activGroup}
                setActiveGroup={setActiveGroup}
                size="large"
                formLabelFontSize={16}
                fullWidth
            />

            {showTable && (
                <Row className="fw" gutter={[10, 10]}>
                    <Col span={24}>
                        <h5 className="fw text-align-start mb-2">
                            Порядок расчета цены
                        </h5>

                        <UTable
                            key={`${dataUpdatedAt}-${existingOrdersUpdatedAt}`}
                            data={data}
                            loading={isFetching}
                            search
                            searchCondition={(row, search) =>
                                row.name
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                            }
                            onRowClick={(row) => {
                                setSelectedRow(row)
                            }}
                            isRowActive={(row) => {
                                return selectedRow?.id === row.id
                            }}
                            columns={[
                                {
                                    columnName: "ID",
                                    render: (row) => row.id,
                                    width: 20,
                                },
                                {
                                    columnName: "Свойство",
                                    render: (row) => row.name,
                                },
                                {
                                    columnName: "Очередь",
                                    width: 20,
                                    render: (row) => {
                                        const defaultValue =
                                            existingOrders?.find(
                                                (v) =>
                                                    v.attributeValue == row.id
                                            )?.order

                                        return (
                                            <InputNumber
                                                bordered={false}
                                                controls={false}
                                                onFocus={(e) => {
                                                    focusRefValue.current =
                                                        e.target.value
                                                }}
                                                type="number"
                                                defaultValue={
                                                    defaultValue || ""
                                                }
                                                formatter={(value) => {
                                                    if (value === 0) {
                                                        return ""
                                                    }
                                                    return value
                                                }}
                                                min={0}
                                                max={100000}
                                                className="fw"
                                                onBlur={(e) => {
                                                    const currentValue =
                                                        e.target.value
                                                    if (
                                                        currentValue !==
                                                        focusRefValue.current
                                                    ) {
                                                        handleSaveOrder(
                                                            row.id,
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    focusRefValue.current = ""
                                                }}
                                            />
                                        )
                                    },
                                },
                            ]}
                        />
                    </Col>
                </Row>
            )}

            {activGroup && activeRegion && (
                <SeriesDetailCombinationOrder
                    activeGroup={activGroup}
                    activeRegion={activeRegion}
                />
            )}
        </Flex.Col>
    )
}
