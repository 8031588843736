import { useQuery } from "@tanstack/react-query"
import { Typography } from "antd"
import { Flex } from "components/UI/Flex"
import UVirtualList from "components/UVirtualList"
import dayjs from "dayjs"
import api from "helpers/api"
import { getFieldsValues } from "helpers/getFieldValue"
import { useMemo, useState } from "react"
import { Uuid } from "types"
import { ListingResponse } from "types/api"
import StandartKitLogItem from "./StandartKitLogItem"

export type StandardLogItem = {
    id: Uuid
    createdAt: string
    log: string[]
}

export default function StandartKits({
    lastGeneretedKitId,
}: {
    lastGeneretedKitId: boolean
}) {
    const { data } = useQuery({
        queryKey: ["listing/standard-kit-generation-log", lastGeneretedKitId],
        queryFn: () =>
            api.getTyped<ListingResponse>(
                "listing/standard-kit-generation-log",
                {
                    count_on_page: Number.MAX_SAFE_INTEGER,
                    sort: "created_at",
                    method: "desc",
                }
            ),
    })

    const [visibleItem, setVisibleItem] = useState<Uuid | null>(null)
    const [additionHeight, setAdditionHeight] = useState(0)

    const tableData = useMemo(() => {
        if (!data || Array.isArray(data)) return []
        return data.entities
            .map((el) =>
                getFieldsValues<StandardLogItem>(
                    el.fields,
                    "id",
                    "createdAt",
                    "log"
                )
            )
            .sort((a, b) =>
                dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
            )
    }, [data])

    return (
        <Flex.Col fullWidth gap={10}>
            <Typography.Title level={5}>
                История генераций комплектов
            </Typography.Title>
            <UVirtualList
                itemCount={tableData.length}
                height={
                    additionHeight +
                    (tableData.length * 50 < 700 ? tableData.length * 50 : 700)
                }
                items={tableData.map((el: (typeof tableData)[number]) => ({
                    data: el,
                    render: (el: StandardLogItem) => (
                        <StandartKitLogItem
                            data={el}
                            visibleItem={visibleItem}
                            toggleItem={(v) =>
                                setVisibleItem((prev) =>
                                    prev === v ? null : v
                                )
                            }
                            setAdditionHeight={(id, height) => {
                                if (visibleItem === id) {
                                    setAdditionHeight(height ?? 0)
                                }
                            }}
                        />
                    ),
                }))}
            />
        </Flex.Col>
    )
}
