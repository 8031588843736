import { useQuery } from "@tanstack/react-query"
import { Col, Row, Select, SelectProps } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { filterOption } from "components/inputs/helpers"
import { Text } from "components/UI"
import api from "helpers/api"
import { CSSProperties } from "react"
import { Uuid } from "types"
import { ListingResponse } from "types/api"
import { SelectEntity } from "../types"
import { getOnSelectData } from "./onSelect"

interface RegionSelectProps {
    setActiveRegion: (v: SelectEntity | null) => void
    activeRegion: SelectEntity | null
    required?: boolean
    layout?: "vertical" | "horizontal"
    skipValues?: Uuid[]
    labelSpan?: number
    inputSpan?: number
    formLabelFontSize?: number
    fullWidth?: boolean
    noLabel?: boolean
    containerStyles?: CSSProperties
    textLabel?: string
}

export function RegionSelect({
    setActiveRegion,
    required,
    layout = "horizontal",
    activeRegion,
    labelSpan,
    inputSpan,
    formLabelFontSize,
    fullWidth,
    noLabel,
    containerStyles,
    textLabel,
    ...rest
}: RegionSelectProps & SelectProps) {
    const { data, isLoading, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: ["get-region"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/region", {
                    method: "asc",
                    sort: "name",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                })
                .then((response) => {
                    return response.entities.map(
                        (el) =>
                            ({
                                label: (
                                    el.fields.find(
                                        (field) => field.name === "country"
                                    )!.value as any
                                )?.name,
                                value: el.fields.find(
                                    (field) => field.name === "id"
                                )!.value,
                            } as DefaultOptionType)
                    )
                }),
        initialData: [],
    })

    const _labelSpan = layout === "horizontal" ? labelSpan ?? 5 : 24
    const _inputSpan = layout === "horizontal" ? inputSpan ?? 19 : 24

    const onChange = (v: any) => {
        setActiveRegion(v ? getOnSelectData(v) : null)
    }

    return (
        <Row
            gutter={[0, 10]}
            className={fullWidth ? "fw" : undefined}
            style={{
                ...containerStyles,
            }}
        >
            {!noLabel && (
                <Col xs={24} md={_labelSpan}>
                    <Text.FormLabel
                        formLabelFontSize={formLabelFontSize}
                        required={required}
                    >
                        Регион:
                    </Text.FormLabel>
                </Col>
            )}
            <Col xs={24} md={_inputSpan}>
                <Select
                    loading={isLoading || isFetching}
                    showSearch
                    filterOption={filterOption}
                    className="fw"
                    placeholder="Выберите регион"
                    options={data}
                    value={activeRegion}
                    onChange={(_, option) => onChange(option)}
                    allowClear
                    {...rest}
                />
            </Col>
        </Row>
    )
}