import { Spin, Typography } from "antd"
import clsx from "clsx"
import { Flex } from "components/UI/Flex"
import { useState } from "react"
import { Uuid } from "types"
import classes from "./StandartKitDetail.module.sass"

export default function StandardKitDetailPicture({ id }: { id: Uuid }) {
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)

    const baseUrl = process.env.REACT_APP_API_HOST ?? ""

    return (
        <Flex.Col fullWidth align="start">
            {error && (
                <Typography.Text strong>
                    Ошибка загрузки изображения
                </Typography.Text>
            )}
            {!loaded && !error && (
                <Flex.Col fullWidth justify="center" fullHeight>
                    <Spin className="m-auto" />
                </Flex.Col>
            )}
            <img
                className={clsx({
                    "--visible": loaded,
                    "--hidden": !loaded,
                    [classes.img]: true,
                })}
                onLoad={() => setLoaded(true)}
                onError={() => setError(true)}
                src={baseUrl + `v1/standard-kit/picture/${id}`}
            />
        </Flex.Col>
    )
}
