import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import { Button, Col, Form, Input, InputNumber, Popover, Row } from "antd"
import { FormProps, useForm } from "antd/es/form/Form"
import clsx from "clsx"
import { Layout } from "components/layouts"
import FieldSelector from "components/QueryBuilder/FieldSelector"
import QueryBuilderResult from "components/QueryBuilder/QueryBuilderResult"
import QueryFormField from "components/QueryBuilder/QueryFormFIeld"
import { AsyncSelect } from "components/UI"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { formLayout } from "helpers/formLayout"
import withMessage, { WithShowMessage } from "hocs/withMessage"
import { useSaveOrAcceptProps } from "hooks/useSaveOrAccept"
import { pageIDs } from "pageConfig"
import { useCallback, useRef, useState } from "react"
import { RuleGroupType } from "react-querybuilder"
import { useHistory, useParams } from "react-router-dom"
import { InputOptionType, Uuid } from "types"
import NomeclatureFiltersCreateItem from "./NomeclatureFiltersCreateGroup"
import NomenclatureFiltersValueEditor from "./NomenclatureFiltersValueEditor"

type CreateOrEditFilterData = {
    parentGroup: Uuid
    name: string
    numericCode: number
    payload: {
        query: RuleGroupType<any>
        id?: Uuid
    }
}

type GetFilterDataResponse = {
    filter: {
        id: Uuid
        name: string
        numericCode: number
        parentGroup: {
            id: Uuid
            name: string
        }
        query?: {
            id: Uuid
            query?: RuleGroupType<any>
        }
    }
}

function NomeclatureFilters({ showMessage }: WithShowMessage) {
    const [showCreateGroup, setShowCreateGroup] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState<null | InputOptionType>(
        null
    )
    const [query, setQuery] = useState<RuleGroupType<any>>()
    const { withLoading, isLoading } = useSaveOrAcceptProps()
    const params = useParams()
    const history = useHistory()
    const { id: itemId } = params as { id?: Uuid }
    const [form] = useForm<{
        parentGroup: InputOptionType
        name: string
        numericCode: number
        id?: Uuid
        query?: RuleGroupType<any>
    }>()
    const redirectRef = useRef<boolean>(true)

    useQuery<GetFilterDataResponse>({
        queryKey: [itemId],
        enabled: !!itemId,
        queryFn: () =>
            api.getTyped<GetFilterDataResponse>(
                `v1/price-nomenclature-filter/${itemId}`
            ),
        onSuccess: (data) => {
            const { filter } = data
            const group: InputOptionType = {
                value: filter.parentGroup.id,
                label: filter.parentGroup.name,
            }
            form.setFieldsValue({
                name: filter.name,
                parentGroup: group,
                numericCode: filter.numericCode,
                query: filter.query,
            })
            setQuery(
                filter?.query?.query
                    ? {
                          ...filter.query?.query,
                          rules: filter.query.query.rules ?? [],
                      }
                    : undefined
            )
            setSelectedGroup(group)
        },
    })

    const getMutation = (value: "save" | "accept") => ({
        create: withLoading(
            (requestData: CreateOrEditFilterData) =>
                api.post(
                    "v1/price-nomenclature-filter/create",
                    {},
                    requestData
                ),
            value,
            1000
        ),
        update: withLoading(
            (requestData: CreateOrEditFilterData) =>
                api.put(
                    `v1/price-nomenclature-filter/update/${itemId}`,
                    {},
                    requestData
                ),
            value,
            1000
        ),
    })

    const onFinish: FormProps["onFinish"] = (values) => {
        if (!query) return
        const requestData: CreateOrEditFilterData = {
            name: values.name,
            numericCode: values.numericCode,
            parentGroup: selectedGroup?.value || "",
            payload: {
                query: query,
                id: itemId,
            },
        }

        const mutatationKey: keyof ReturnType<typeof getMutation> = itemId
            ? "update"
            : "create"
        const mutate = getMutation(redirectRef.current ? "save" : "accept")[
            mutatationKey
        ]
        mutate(requestData)
            .then(() => {
                if (redirectRef.current) {
                    setTimeout(() => {
                        history.push(`/${pageIDs.economicNomeclatureFilters}`)
                    }, 3000)
                }
                showMessage({
                    type: "success",
                    content: "Успешно",
                })
            })
            .catch((error) => {
                showMessage({
                    type: "error",
                    content: error.message,
                })
            })
    }

    const onSave = useCallback((redirect: boolean) => {
        redirectRef.current = redirect
        form.submit()
    }, [])

    return (
        <Layout.Detail
            pageTitle="Фильтры номенклатуры"
            pageId={pageIDs.economicNomeclatureFilters}
            onSaveProps={{
                onSave: () => onSave(true),
                disabled: !query,
                isLoading: isLoading === "save",
            }}
            onAcceptProps={{
                onAccept: () => onSave(false),
                disabled: !query,
                isLoading: isLoading === "accept",
            }}
        >
            <Flex.Col gap={10} fullWidth>
                <Form
                    form={form}
                    className="fw"
                    {...formLayout}
                    onFinish={onFinish}
                >
                    <Flex.Col gap={10} fullWidth>
                        <Form.Item
                            name={"parentGroup"}
                            className="fw"
                            label="Группа"
                        >
                            <Row
                                gutter={[10, 0]}
                                className="fw"
                                align={"middle"}
                            >
                                <Col span={23}>
                                    <AsyncSelect
                                        className="fw"
                                        value={selectedGroup}
                                        dataUrl="price-nomenclature-filters-group"
                                        onChange={(v: InputOptionType) => {
                                            setSelectedGroup(v)
                                        }}
                                    />
                                </Col>
                                <Col span={1}>
                                    <Popover
                                        title="Создать группу"
                                        placement="right"
                                    >
                                        <Button
                                            icon={
                                                showCreateGroup ? (
                                                    <CloseCircleOutlined />
                                                ) : (
                                                    <PlusCircleOutlined />
                                                )
                                            }
                                            onClick={() =>
                                                setShowCreateGroup(
                                                    (prev) => !prev
                                                )
                                            }
                                            type="primary"
                                        />
                                    </Popover>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Flex.Col
                            fullWidth
                            className={clsx({
                                "--visible": showCreateGroup,
                                "--hidden": !showCreateGroup,
                            })}
                        >
                            <NomeclatureFiltersCreateItem
                                onCreate={(data) => {
                                    setSelectedGroup(data)
                                    setShowCreateGroup(false)
                                }}
                            />
                        </Flex.Col>

                        <Form.Item
                            label="Название"
                            className="fw"
                            name={"name"}
                        >
                            <Input size="large" className="fw" />
                        </Form.Item>

                        <Form.Item
                            label="Код"
                            className="fw"
                            name={"numericCode"}
                        >
                            <InputNumber size="large" className="fw" />
                        </Form.Item>

                        <Form.Item label="Фильтр" name={"query"} className="fw">
                            <QueryFormField
                                query={query}
                                getEntitiesURL="v1/query-builder/price-nomenclature-filter/entities-list"
                                controlElements={{
                                    valueEditor: NomenclatureFiltersValueEditor,
                                    fieldSelector: FieldSelector,
                                }}
                                onQueryChange={(q) => {
                                    setQuery({
                                        ...q,
                                        rules: q?.rules ?? [],
                                    })
                                }}
                                translations={{
                                    addGroup: { label: "Добавить группу" },
                                    addRule: { label: "Добавить правило" },
                                }}
                            />
                        </Form.Item>
                        <Flex.Row fullWidth gap={20}>
                            <QueryBuilderResult
                                dataUrl="v1/query-builder/price-nomenclature-filter/result"
                                query={query}
                            />
                        </Flex.Row>
                    </Flex.Col>
                </Form>
            </Flex.Col>
        </Layout.Detail>
    )
}

export default withMessage(NomeclatureFilters)
