import { useQuery } from "@tanstack/react-query"
import { Button, Col, DatePicker, Form, Input, Row, Typography } from "antd"
import { FormProps, useForm } from "antd/es/form/Form"
import { Layout } from "components/layouts"
import FormApiListingSelect from "components/Selects/newSelects/FormApiListingSelect"
import FormApiSelect from "components/Selects/newSelects/FormApiSelect"
import { Flex } from "components/UI/Flex"
import UNumberItem, { getUNumberValue } from "components/UNumberItem"
import { Dayjs } from "dayjs"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { pageIDs } from "pageConfig"
import { Currency } from "pages/CostCollector/СostCollectorModelTable"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Uuid } from "types"
import CalculationRulesTables from "./CaclulationRules.Tables"
import CalculationRulesCurrency from "./CalculationRules.Currency"
import CalculationRulesPrecision from "./CalculationRules.Precision"
import { useCalculationRulesStore } from "./CalculationRules.store"
import { CreateRuleData, GetRuleData } from "./CalculationRules.types"
import { tranformInputCalculationRuleData } from "./helpers"

export default function CalculationRules() {
    const [form] = useForm<CreateRuleData>()
    const history = useHistory()
    const {
        setActiveProducer,
        priceCalculationRulePriceMarginPresetLink,
        nomenclatureFilters,
        activeProducer,
        activeGroup,
        setActiveGroup,
        setInitialPriceMarginPresetLink,
        setInitialNomenclatureFilters,
    } = useCalculationRulesStore()

    const { id } = useParams() as { id?: Uuid }

    useQuery({
        queryKey: ["price-calculation-rule", id],
        refetchOnWindowFocus: false,
        enabled: !!id,
        queryFn: () =>
            api.getTyped<GetRuleData>("v1/price-calculation-rule/" + id),
        onSuccess: (data) => {
            const formData = tranformInputCalculationRuleData(data)
            form.setFieldsValue(formData)
            setInitialNomenclatureFilters(
                data.priceNomenclatureFilters.map((el) => el.id)
            )
            setInitialPriceMarginPresetLink(
                data.priceCalculationRulePriceMarginPresetLink.map((el) => ({
                    id: el.priceMarginPreset.id,
                    calculationType: el.calculationType,
                    order: el.order,
                }))
            )
            setActiveGroup(
                data.itemsGroup
                    ? {
                          label: data.itemsGroup.name,
                          value: data.itemsGroup.id,
                      }
                    : null
            )
            setActiveProducer(
                data.producer
                    ? {
                          label: data.producer.name,
                          value: data.producer.id,
                      }
                    : null
            )
        },
    })

    const { showNotification, handleApiError } = useNotifications()
    const [postLoading, setPostLoading] = useState(false)

    const onFinish: FormProps["onFinish"] = (values) => {
        if (postLoading) return
        setPostLoading(true)
        const data = { ...values } as any
        data.nomenclatureFilters = nomenclatureFilters
        data.priceCalculationRulePriceMarginPresetLink =
            priceCalculationRulePriceMarginPresetLink
        const { startAt, endAt }: { startAt: Dayjs; endAt: Dayjs } = data
        data.startAt = startAt.startOf("day").toDate()
        data.endAt = endAt.endOf("day").toDate()
        data.outputCurrencyRate = 0
        if (!data.calculationType) {
            data.calculationType = null
        }
        data.exportCurrencyAmount = getUNumberValue(data.exportCurrencyAmount)
        data.exportCurrencyRate = getUNumberValue(data.exportCurrencyRate)
        data.inputCurrencyAmount = getUNumberValue(data.inputCurrencyAmount)
        data.inputCurrencyRate = getUNumberValue(data.inputCurrencyRate)
        data.value = getUNumberValue(data.value)
        const handler = id ? api.put.bind(api) : api.post.bind(api)
        const action = id ?? "create"

        handler("v1/price-calculation-rule/" + action, {}, data)
            .then(() => {
                showNotification({
                    type: "success",
                    message: "Сохранено",
                    cb() {
                        setPostLoading(false)
                        history.push("/economic/" + pageIDs.calculationRules)
                    },
                })
            })
            .catch((error: any) => {
                handleApiError(error)
                setPostLoading(false)
            })
    }

    useEffect(() => {
        form.setFieldValue("producer", activeProducer?.value)
    }, [activeProducer])

    useEffect(() => {
        form.setFieldValue("itemsGroup", activeGroup?.value)
    }, [activeGroup])

    return (
        <Layout.Detail
            pageTitle="Правила расчётов"
            pageId={"economic/" + pageIDs.calculationRules}
            contentTitle=""
        >
            <Flex.Col className="fw" margin={[0, 0, 20, 0]}>
                <Form
                    initialValues={{
                        outputCurrencyRate: 0,
                    }}
                    form={form}
                    className="fw"
                    labelCol={{ span: 6 }}
                    labelAlign="left"
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                >
                    <FormApiListingSelect
                        target="items-group"
                        label="Группа"
                        name="itemsGroup"
                        onChange={setActiveGroup}
                    />

                    <Form.Item
                        name={"outputCurrencyRate"}
                        style={{ display: "none" }}
                    >
                        <Input size="large" />
                    </Form.Item>

                    <FormApiListingSelect
                        target="producer"
                        label="Поставщик"
                        name="producer"
                        onChange={setActiveProducer}
                    />
                    <Form.Item name={"numericCode"} label="Код">
                        <Input
                            size="large"
                            className="form-control"
                            type="number"
                            step={1}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Заполните поле",
                            },
                        ]}
                        name="name"
                        label="Наименование"
                    >
                        <Input size="large" className="form-control" />
                    </Form.Item>

                    <Form.Item
                        label="Период действия фильтра"
                        className="noMargin"
                    >
                        <Row
                            gutter={[20, 20]}
                            style={{ marginBottom: 20 }}
                            justify="center"
                        >
                            <Col span={11}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Заполните поле",
                                        },
                                    ]}
                                    name="startAt"
                                    className="noMargin fw"
                                >
                                    <DatePicker size="large" className="fw" />
                                </Form.Item>
                            </Col>
                            <Col
                                span={2}
                                className="flex flex-column align-center justify-center"
                            >
                                <Typography.Text>-</Typography.Text>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    className="noMargin fw"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Заполните поле",
                                        },
                                    ]}
                                    name="endAt"
                                >
                                    <DatePicker size="large" className="fw" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>

                    <FormApiSelect
                        getOptionUrl="v1/price-calculation-rule/calculation-type"
                        name={"calculationType"}
                        label="Входной тип цены"
                        transformResponse={(v: Record<string, string>) =>
                            Object.entries(v).map(([value, label]) => ({
                                label,
                                value,
                            }))
                        }
                    />

                    <FormApiListingSelect
                        target="price-type"
                        name={"priceType"}
                        label="Выходной тип цены"
                        datakeys={["currency"]}
                        onChange={(v) => {
                            const data = v?.data as { currency: Currency }
                            form.setFieldValue(
                                "exportCurrency",
                                data.currency.name
                            )
                        }}
                    />

                    <UNumberItem
                        floatRank={2}
                        useInForm={{
                            label: "Наценка в %",
                            name: "value",
                        }}
                    />

                    <CalculationRulesCurrency />

                    <CalculationRulesPrecision />

                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={postLoading}
                        disabled={postLoading}
                    >
                        Сохранить
                    </Button>
                </Form>

                <CalculationRulesTables />
            </Flex.Col>
        </Layout.Detail>
    )
}
