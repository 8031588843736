import { Button, Card, Col, Row } from "antd"
import { Layout } from "components/layouts"
import { EnititesSelect } from "components/Selects/newSelects/EntitiesSelect"
import { Flex } from "components/UI/Flex"
import classes from "./CostCollector.module.sass"
import { CostCollectorModelTable } from "./СostCollectorModelTable"
import { useCostCollectorStore } from "./CostCollector.store"

export enum PriceFormField {
    priceSettingDate = "priceSettingDate",
    operationType = "operationType",
    value = "value",
    rounding = "rounding",
}

const CostCollector = () => {
    const { setItemsGroup, setProducer, itemsGroup, producer, resetValue, resetGroupAndProducer, applied, setApplied } = useCostCollectorStore()

    return (
        <Layout.Container
            pageTitle="Сборщик себестоимости"
            className="cost-collector"
        >
            <Flex.Col gap={10} fullWidth>
                <Card className="fw">
                    <EnititesSelect
                        containerStyles={{
                            width: "100%",
                        }}
                        reset={resetValue}
                        entities={["producer"]}
                        onGroupChange={setItemsGroup}
                        onProducerChange={setProducer}
                    />

                    <Flex.Row fullWidth gap={20} margin={[20, 0, 0]}>
                        <Row className="fw">
                            <Col xs={24} md={5}></Col>
                            <Col xs={24} md={17}>
                                <Flex.Row gap={20} fullWidth justify="start">
                                    <Button
                                        type="primary"
                                        className={classes.formButton}
                                        onClick={() => setApplied(true)}
                                        disabled={!itemsGroup || !producer}
                                    >
                                        Применить
                                    </Button>
                                    <Button
                                        type="primary"
                                        className={classes.formButton}
                                        disabled={!itemsGroup && !producer}
                                        onClick={resetGroupAndProducer}
                                    >
                                        Сбросить
                                    </Button>
                                </Flex.Row>
                            </Col>
                        </Row>
                    </Flex.Row>
                </Card>

                {itemsGroup && producer && applied && (
                    <CostCollectorModelTable />
                )}
            </Flex.Col>
        </Layout.Container>
    )
}

export default CostCollector
