import { NomenclatureResponse } from "components/QueryBuilder/QueryBuilderResult"
import { SelectEntity } from "components/Selects/types"
import {
    ApplyState,
    DatePickState,
    GroupSelectState,
    ProducerSelectState,
    QueryBulderState,
    SeriesSelectState,
    TotalFoundState,
} from "types/interfaces"
import { create } from "zustand"

interface IPriceRecalculate
    extends GroupSelectState,
        ProducerSelectState,
        SeriesSelectState,
        DatePickState,
        ApplyState,
        QueryBulderState,
        TotalFoundState {
    allFilled: () => boolean
    onlyCostPrice: boolean
    setOnlyCostPrice: (v: boolean) => void
    priceTypes: SelectEntity[]
    setPriceTypes: (v: SelectEntity[]) => void
    modalOpened: boolean
    setOpenModal: (v: boolean) => void
    foundNomenclature: NomenclatureResponse | null
    setFoundNomenclature: (v: NomenclatureResponse | null) => void
}

export const usePriceRecalculateState = create<IPriceRecalculate>(
    (set, get) => ({
        activeGroup: null,
        activeSeries: null,
        activeProducer: null,
        selectedDate: null,
        apply: false,
        onlyCostPrice: false,
        query: undefined,
        totalFound: 0,
        priceTypes: [],
        modalOpened: false,
        foundNomenclature: null,
        setFoundNomenclature(v) {
            return set((state) => ({
                ...state,
                foundNomenclature: v,
            }))
        },
        setOpenModal(v) {
            return set((state) => ({
                ...state,
                modalOpened: v,
            }))
        },
        setPriceTypes(v) {
            return set((state) => ({
                ...state,
                priceTypes: v,
            }))
        },
        setActiveGroup: (v) =>
            set((state) => ({
                ...state,
                activeGroup: v,
                apply: false,
            })),
        setActiveProducer: (v) =>
            set((state) => ({
                ...state,
                activeProducer: v,
                apply: false,
            })),
        setActiveSeries: (v) =>
            set((state) => ({
                ...state,
                activeSeries: v,
                apply: false,
            })),
        setSelectedDate: (v) =>
            set((state) => ({
                ...state,
                selectedDate: v,
                apply: false,
            })),
        dateString() {
            return get().selectedDate?.format("YYYY-MM-DD") ?? ""
        },
        setApply(v) {
            return set((state) => ({
                ...state,
                apply: v,
            }))
        },
        allFilled: () =>
            !!get().activeGroup &&
            !!get().activeProducer &&
            !!get().activeSeries &&
            !!get().selectedDate,
        setOnlyCostPrice(v) {
            return set((state) => ({
                ...state,
                onlyCostPrice: v,
            }))
        },
        setQuery(v) {
            return set((state) => ({
                ...state,
                query: v,
            }))
        },
        setTotalFound(v) {
            return set((state) => ({
                ...state,
                totalFound: v,
            }))
        },
    })
)
