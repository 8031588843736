import { useQuery } from "@tanstack/react-query"
import { Layout } from "components/layouts"
import { DisabledInput } from "components/UI"
import { Flex } from "components/UI/Flex"
import { FormLine } from "components/UI/FormLine"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { usePageId } from "hooks/usePageId"
import { pageIDs } from "pageConfig"
import { Link } from "react-router-dom"
import { Uuid } from "types"
import StandardKitDetailPicture from "./StandardKitDetail.Picture"
import classes from "./StandartKitDetail.module.sass"

interface AttributeFirst {
    id: Uuid
    name: string
    isActive: boolean
    isRequired: boolean
    datatype: string
    description: string
    sort: string
    valuesList: ValuesList1
}

interface ClassificationAccordance {
    id: Uuid
    producer: Producer
    itemsGroup: ItemsGroups
    attributeFirst: AttributeFirst
    attributeSecond: AttributeFirst
    attributeFirstValue: string
    attributeSecondValue: string
    tnved: MeasureUnit1
    okrb: MeasureUnit1
    shortRepresentation: ShortRepresentation2
    tnvedName: string
    okrbName: string
}

interface Country {
    id: Uuid
    name: string
    code: string
    alpha2: string
    alpha3: string
}

interface HeadNomenclature {
    id: Uuid
    name: string
    accountingName: string
    isInStock: boolean
    nomenclatureRegionStock: NomenclatureRegionStock[]
    discontinued: boolean
    series: Series
    itemsGroup: ItemsGroups
    producer: Producer
    model: Model
    pictureUrl: string
    prices: any[]
    altDeliveryTime: number
    measureUnit: MeasureUnit1
    createdAt: string
    updatedAt: string
    renderedValues: RenderedValues
    classificationAccordance: ClassificationAccordance
    tnved: MeasureUnit1
    okrb: MeasureUnit1
    availability: any[]
}

interface ItemsGroups {
    id: Uuid
    name: string
    isActive: boolean
    shortRepresentation: ShortRepresentation
    isPublished: boolean
}

interface MeasureUnit {
    id: Uuid
    code: number
    name: string
    unit: string
}

interface MeasureUnit1 {
    id: Uuid
    name: string
}

interface Model {
    attributeValues: any[]
    attributeValuesIds: any[]
    attributeValuesMap: any[]
    attributeValuesMapWithAttributesIds: any[]
    id: Uuid
    name: string
    alternativeName: string
    alternativeAccountingName: string
    series: Series
    itemsGroup: ItemsGroups
    cover: string
    coverInside: string
    isPublished: boolean
    shortRepresentation: ShortRepresentation1
    copyType: string
    measureUnit: MeasureUnit
    deliveryTime: string
}

interface Nomenclature {
    id: Uuid
    itemsGroup: ItemsGroups
    producer: Producer
    series: Series
    model: Model
    name: string
}

interface NomenclatureRegionStock {
    id: Uuid
    name: string
    country: Country
    vat: number
}

interface Nomenclatures {
    id: Uuid
    nomenclature: Nomenclature
    pcCount: number
    kitCount: number
}

interface Producer {
    id: Uuid
    name: string
    importCountry: Country
    producingCountry: Country
    isPublished: boolean
}

type RenderedValues = Record<string, string>

interface Series {
    id: Uuid
    name: string
    deliveryTime: number
    copyType: string
    isPublished: boolean
    producer: Producer
    shortRepresentation: ShortRepresentation
    itemsGroups: ItemsGroups[]
}

interface ShortRepresentation {
    code: string
    name: string
}

interface ShortRepresentation1 {
    code: string
    name: string
    alternativeName: string
}

interface ShortRepresentation2 {
    tnved: string
    okrb: string
}

type ValuesList1 = Record<Uuid, string>

interface ResponseData {
    id: Uuid
    name: string
    headNomenclature: HeadNomenclature
    itemsGroup: ItemsGroups
    producer: Producer
    series: Series
    model: Model
    nomenclatures: Nomenclatures[]
    deliveryTime: number
    price: number
    currency: any
}

export default function StandardKitDetail() {
    const { id } = usePageId()

    const { data, isFetching } = useQuery<ResponseData>({
        queryKey: [id, "reference/standard-kit"],
        enabled: !!id,
        queryFn: () => api.getTyped("reference/standard-kit/" + id),
    })

    return (
        <Layout.Detail
            pageId={pageIDs.standardKit}
            pageTitle="Стандартный комплект"
            loading={isFetching}
            contentTitle={data?.name ?? ""}
            infoContent={id ? <StandardKitDetailPicture id={id} /> : undefined}
        >
            {data && (
                <Flex.Col
                    gap={20}
                    fullWidth
                    margin={[25, 0, 0]}
                    className={classes.root}
                >
                    <FormLine label="Уникальный номер комплекта">
                        <DisabledInput value={data.id} />
                    </FormLine>

                    <FormLine label="Название комплекта">
                        <DisabledInput value={data.name} />
                    </FormLine>

                    {data.model && (
                        <FormLine label="Модель">
                            <Link
                                to={`/model/update-${data.model.id}`}
                                target="_blank"
                            >
                                {data.model.name}
                            </Link>
                        </FormLine>
                    )}

                    {data.headNomenclature && (
                        <FormLine label="Обобщенная номенклатура">
                            <Link
                                to={`/nomenclature/update-${data.headNomenclature.id}`}
                                target="_blank"
                            >
                                {data.headNomenclature.name}
                            </Link>
                        </FormLine>
                    )}

                    <FormLine label="Номенклатура входящая в комплект">
                        <Flex.Col gap={10} align="start">
                            <UTable
                                data={data.nomenclatures.sort((a, b) =>
                                    a.nomenclature.itemsGroup.name.localeCompare(
                                        b.nomenclature.itemsGroup.name
                                    )
                                )}
                                columns={[
                                    {
                                        columnName: "",
                                        width: 20,
                                        render: (row) =>
                                            row.nomenclature.itemsGroup.name,
                                    },
                                    {
                                        columnName: "Номенклатура",
                                        render: (row) => (
                                            <Link
                                                to={`/nomenclature/update-${row.nomenclature.id}`}
                                                target="_blank"
                                            >
                                                {row.nomenclature.name}
                                            </Link>
                                        ),
                                        width: 60,
                                    },
                                    {
                                        columnName: "шт.",
                                        render: (row) => row.pcCount,
                                        width: 10,
                                    },
                                    {
                                        columnName: "Компл.",
                                        render: (row) => row.kitCount,
                                        width: 10,
                                    },
                                ]}
                            />
                        </Flex.Col>
                    </FormLine>

                    <FormLine label="Итоговая стоимость за комплект">
                        <DisabledInput
                            value={data.price.toFixed(2).toString()}
                        />
                    </FormLine>

                    <FormLine label="Фотография комплекта"></FormLine>

                    <FormLine label="Срок поставки">
                        <DisabledInput
                            value={data.deliveryTime.toString() + " д."}
                        />
                    </FormLine>
                </Flex.Col>
            )}
        </Layout.Detail>
    )
}
