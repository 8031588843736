import { useQuery } from "@tanstack/react-query"
import { Form, FormItemProps } from "antd"
import Select, { DefaultOptionType } from "antd/es/select"
import api from "helpers/api"
import { SelectOption } from "types"
import { SelectEntity } from "../types"

export default function FormApiSelect<ResponseData>({
    getOptionUrl,
    transformResponse,
    getOptionProps,
    onChange,
    ...props
}: FormItemProps & {
    getOptionUrl: string
    getOptionProps?: object
    transformResponse: (v: any) => SelectOption<any>[]
    onChange?: (v: SelectEntity | null) => void
}) {
    const { data, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: [getOptionUrl],
        queryFn: () =>
            api
                .getTyped<ResponseData>(getOptionUrl, getOptionProps)
                .then(transformResponse),
        initialData: [],
    })

    return (
        <Form.Item {...props}>
            <Select
                size="large"
                className="fw"
                options={data}
                loading={isFetching}
                allowClear
                onChange={(_, option) =>
                    onChange && onChange(option as SelectOption<any> | null)
                }
            />
        </Form.Item>
    )
}
