import { Modal } from "antd"
import Link from "antd/es/typography/Link"
import { UTable } from "components/UTable"
import { NomenclatureResponse } from "./QueryBuilderResult"

type IQueryBuilderShowResult = {
    setOpenModal: (v: boolean) => void
    openModal: boolean
    loading: boolean
    found?: NomenclatureResponse
}

export default function QueryBuilderShowResult({
    openModal,
    setOpenModal,
    loading,
    found,
}: IQueryBuilderShowResult) {
    return (
        <Modal
            open={openModal}
            onCancel={() => setOpenModal(false)}
            title="Найденная номенклатура"
            cancelButtonProps={{ hidden: true }}
            okText="Закрыть"
            onOk={() => setOpenModal(false)}
        >
            {found && (
                <UTable
                    loading={loading}
                    data={found.entityList}
                    search
                    searchCondition={(row, search) =>
                        row.name.toLowerCase().includes(search.toLowerCase())
                    }
                    columns={[
                        {
                            columnName: "Название",
                            render(v) {
                                return (
                                    <Link
                                        href={`/nomenclature/update-${v.id}`}
                                        target="_blank"
                                    >
                                        {v.name}
                                    </Link>
                                )
                            },
                        },
                    ]}
                />
            )}
        </Modal>
    )
}
