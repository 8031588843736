import { pageIDs } from "pageConfig"
import { Permission } from "./permissions"

export type MenuItemContainerData = {
    title: string
    items: MenuItemData[]
}

export type MenuItemData = {
    slug: string
    title: string
    permission?: Permission
}

export const menuData: MenuItemContainerData[] = [
    {
        title: "Контроль доступа",
        items: [
            {
                slug: "role",
                title: "Роли",
                permission: "role",
            },
            {
                slug: "user",
                title: "Пользователи",
                permission: "user",
            },
        ],
    },
    {
        title: "Каталоги",
        items: [
            {
                slug: "attribute",
                title: "Свойства",
                permission: "attribute",
            },
            {
                slug: pageIDs.availabilityNomenclatureFilters,
                title: "Фильтры доступности номенклатуры",
                permission: "availability-nomenclature-filter",
            },
            {
                slug: "model-attribute",
                title: "Свойства моделей",
                permission: "model-attribute",
            },
            {
                slug: "items-group",
                title: "Номенклатурные группы",
                permission: "items-group",
            },
            {
                slug: "producer",
                title: "Поставщики",
                permission: "producer",
            },
            {
                slug: "series",
                title: "Серии",
                permission: "series",
            },
            {
                slug: "model",
                title: "Модели",
                permission: "model",
            },
            {
                slug: "configuration/models-attributes-values-binding",
                title: "Серии и модели",
                permission: "configuration/models-attributes-values-binding",
            },
            {
                slug: pageIDs.attributeValuesСompatibility,
                title: "Сочетания значений",
                permission: "configuration/attribute-values-compatibility",
            },
            {
                slug: pageIDs.kits,
                title: "Комплекты",
                permission: "kit",
            },
            {
                slug: `${pageIDs.standardKit}`,
                title: "Собранные комплекты",
            },
            {
                slug: pageIDs.deliveryTime,
                title: "Сроки изготовления",
                permission: "delivery-time-filter",
            },
            {
                slug: pageIDs.modelAttributeValueCriteria,
                title: "Стандартные размеры",
                permission: "model-attribute-value-criteria",
            },
            {
                slug: pageIDs.requisites,
                title: "Изменение реквизитов",
            },
        ],
    },
    {
        title: "Экономика",
        items: [
            {
                slug: "region",
                title: "Регионы",
                permission: "region",
            },
            {
                slug: "currency",
                title: "Валюты",
                permission: "currency",
            },
            {
                slug: "price-type",
                title: "Типы цен",
                permission: "price-type",
            },
            {
                slug: pageIDs.economicSeries,
                title: "Серии",
                permission: "economic/series",
            },
            {
                slug: pageIDs.economicProducer,
                title: "Поставщики",
                permission: "economic/producer",
            },
            {
                slug: pageIDs.economicNomeclatureFilters,
                title: "Фильтры номенклатуры",
                permission: "price-nomenclature-filter",
            },
            {
                slug: "economic/" + pageIDs.priceMarginPreset,
                title: "Типы наценок",
                permission: "economic/price-margin-preset",
            },
            {
                slug: `economic/${pageIDs.calculationRules}`,
                title: "Правила расчёта",
                permission: "economic/price-calculation-rule",
            },
            {
                slug: pageIDs.costCollector,
                title: "Сборщик себестоимости",
                permission: "cost-collector",
            },
            {
                slug: pageIDs.pricesRecalculate,
                title: "Пересчёт цен",
                permission: "prices-recalculate",
            },
            {
                slug: pageIDs.attributeValuesPrices,
                title: "Сочетания значений",
                permission: "economic/attribute-values-prices",
            },
            {
                slug: `economic/${pageIDs.attributeCombination}`,
                title: "Справочник сочетаний",
                permission: "economic/attribute-combination",
            },
            {
                slug: `economic/${pageIDs.staller}`,
                title: "Настройки Сталлер",
                permission: "economic/model-value-staller",
            },
            {
                slug: "economic/set-nomenclature-prices",
                title: "Ручная установка цен",
            },
        ],
    },
    {
        title: "Конфигурация",
        items: [
            {
                slug: "configuration/configurator",
                title: "Конфигуратор",
                permission: "configuration/configurator",
            },
            {
                slug: "nomenclature",
                title: "Номенклатура",
                permission: "nomenclature",
            },
            {
                slug: "nomenclature/mass-generation",
                title: "Генератор номенклатур",
                permission: "nomenclature/mass-generation",
            },
            {
                slug: pageIDs.massAttributeResplace,
                title: "Замена свойств",
                permission: "attribute-replace",
            },
        ],
    },
    {
        title: "Классификаторы",
        items: [
            {
                slug: "classification-accordance",
                title: "Таблица соответствий",
                permission: "classification-accordance",
            },
            {
                slug: "tnved",
                title: "ТНВЭД",
                permission: "tnved",
            },
            {
                slug: "okrb",
                title: "ОКРБ",
                permission: "okrb",
            },
        ],
    },
    {
        title: "Сборщик графики",
        items: [
            {
                slug: "pictures/layer-by-layer",
                title: "По слоям",
                permission: "pictures/layer-by-layer",
            },
            {
                slug: "pictures/ready-pictures-configurator",
                title: "Готовые модели",
                permission: "pictures/ready-pictures-configurator",
            },
            {
                slug: "pictures/picture-mass-uploader",
                title: "Массовая загрузка изображений",
                permission: "pictures/picture-mass-uploader",
            },
            {
                slug: "pictures/settings",
                title: "Настройки",
                permission: "pictures/settings",
            },
        ],
    },
]
