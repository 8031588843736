import { useQuery } from "@tanstack/react-query"
import { Checkbox, Spin, Typography } from "antd"
import { Flex } from "components/UI/Flex"
import { FormLine } from "components/UI/FormLine"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { Country, ListingResponse } from "types/api"
import { useRequisitesStore } from "./Requisites.store"

export default function RequisitesRegions() {
    const { isSetRegion, setRegion } = useRequisitesStore()

    const { data, isFetching } = useQuery({
        queryKey: ["listing/region"],
        queryFn: () =>
            api.getTyped<ListingResponse>("listing/region").then((response) => {
                return response.entities.map(
                    (entity) =>
                        getFieldValue<Country>(entity.fields, "country")!
                )
            }),
        initialData: [],
    })

    return (
        <FormLine label="Регионы">
            <Flex.Row fullWidth align="center" gap={20} justify="start">
                {isFetching && <Spin />}
                {data.map((el) => (
                    <Flex.Row gap={10}>
                        <Typography.Text style={{ fontSize: 16 }}>
                            {el?.name}
                        </Typography.Text>
                        <Checkbox
                            checked={isSetRegion(el)}
                            onChange={() => setRegion(el)}
                        />
                    </Flex.Row>
                ))}
            </Flex.Row>
        </FormLine>
    )
}
