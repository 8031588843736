import { Divider, Typography } from "antd"
import { AsyncSelect } from "components/UI"
import { Flex } from "components/UI/Flex"
import { usePriceRecalculateState } from "./PriceRecalculate.state"

export default function PriceRecalculatePriceTypes() {
    const { priceTypes, setPriceTypes, onlyCostPrice } =
        usePriceRecalculateState()
    return (
        <>
            <Divider style={{ margin: 0 }} />
            <Flex.Col fullWidth align="start">
                <Typography.Title level={5}>Тип цены</Typography.Title>
                <AsyncSelect
                    placeholder="Тип цены"
                    className="fw"
                    dataUrl="price-type"
                    value={priceTypes}
                    onChange={setPriceTypes}
                    isMulti
                    isDisabled={onlyCostPrice}
                    externalOptions={[
                        {
                            label: "Себестоимость",
                            value: null,
                        },
                    ]}
                />
            </Flex.Col>
        </>
    )
}
