import { AxiosError } from "axios"
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useState,
} from "react"
import { createPortal } from "react-dom"
import { SymphonyError } from "types"

export type NotificationTypes =
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "info"
    | "warning"
    | "light"
    | "dark"

const DEFAULT_TIMER = 2000
const DEFAULT_DELAY = 2000

type NotificationData = {
    message: string
    type?: NotificationTypes
    time?: number
    delay?: number
    cb?: () => void
}

type NotificationElement = NotificationData & { id: number }

interface INotificationContext {
    showNotification: (data: NotificationData) => void
    handleApiError: (error: AxiosError<any>) => void
}

const NotificationContext = createContext({} as INotificationContext)

interface NotificationProps {
    data: NotificationElement
    removeNotification: (id: number) => void
}

const Notification = ({ data, removeNotification }: NotificationProps) => {
    const animationStyle = `notification-hide ${
        data.time ?? DEFAULT_TIMER
    }ms linear ${data.delay ?? DEFAULT_DELAY}ms`

    return (
        <div
            role="alert"
            className={"alert alert-" + (data.type ?? "primary")}
            onAnimationEnd={() => removeNotification(data.id)}
            style={{ animation: animationStyle }}
        >
            {data.message}
        </div>
    )
}

interface INotifications {
    data: NotificationElement[]
    removeNotification: (id: number) => void
}

const Notifications = ({ data, removeNotification }: INotifications) => {
    const root = document.getElementById("notification-portal")

    if (!root || !data.length) return <></>

    return createPortal(
        <div className="notification-wrapper">
            {data.map((el) => (
                <Notification
                    data={el}
                    key={el.id}
                    removeNotification={removeNotification}
                />
            ))}
        </div>,
        root
    )
}

export const NotificationWrapper = ({ children }: PropsWithChildren) => {
    const [data, setData] = useState<NotificationElement[]>([])

    const showNotification = useCallback((data: NotificationData) => {
        const newNotification: NotificationElement = {
            ...data,
            id: Date.now(),
        }
        setData((data) => [...data, newNotification])
    }, [])

    const removeNotification = useCallback(
        (id: number) => {
            setData((prev) => {
                const cb = prev.find((el) => el.id === id)?.cb
                if (cb) {
                    cb()
                }
                return prev.filter((el) => el.id !== id)
            })
        },
        [setData]
    )

    const handleApiError = useCallback(
        (error: AxiosError<SymphonyError | string>) => {
            const notification: NotificationData = {
                type: "danger",
                message: "",
            }
            if (typeof error.response?.data === "string") {
                notification.message = error.response.data
                return showNotification(notification)
            }
            const responeError = error.response?.data?.detail
            if (responeError) {
                if (typeof responeError === "object") {
                    notification.message = `Неизвестная ошибка: код ${error.response?.status}`
                } else {
                    notification.message = responeError
                }
            }
            showNotification(notification)
        },
        [showNotification]
    )

    return (
        <NotificationContext.Provider
            value={{
                showNotification,
                handleApiError,
            }}
        >
            <>
                {children}
                <>
                    <Notifications
                        data={data}
                        removeNotification={removeNotification}
                    />
                </>
            </>
        </NotificationContext.Provider>
    )
}

export const useNotifications = () => useContext(NotificationContext)
