import { useQuery } from "@tanstack/react-query"
import { Form, Input, Select } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { SelectEntity } from "components/Selects/types"
import UNumberItem from "components/UNumberItem"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { useSelector } from "react-redux"
import { RootState } from "store/types"
import { CurrencyData, ListingResponse } from "types/api"
import { Flex } from "../../components/UI/Flex"
import { useCalculationRulesStore } from "./CalculationRules.store"

export default function CalculationRulesCurrency() {
    const region = useSelector((state: RootState) => state.region.region)
    const { activeProducer } = useCalculationRulesStore()

    const { data: defaultCurrency, isFetching: defaultCurrencyFetching } =
        useQuery<SelectEntity | null>({
            queryKey: ["producer-default-currency", activeProducer, region],
            enabled: !!activeProducer && !!region,
            queryFn: () =>
                api
                    .getTyped<{ currency: CurrencyData }>(
                        "v1/economics/producer/default-currency",
                        {
                            producer: activeProducer?.value,
                            region: region?.value,
                        }
                    )
                    .then((data) => {
                        return data?.currency
                            ? {
                                  label: data.currency.isoCode,
                                  value: data.currency.id,
                              }
                            : null
                    }),
        })

    const { data: options, isFetching: defaultOptionsFetching } = useQuery({
        queryKey: ["get-currency"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/currency", {
                    method: "asc",
                    sort: "name",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                })
                .then((response) => {
                    return response.entities.map(
                        (el) =>
                            ({
                                label: `[${getFieldValue(
                                    el.fields,
                                    "isoCode"
                                )}] ${getFieldValue(el.fields, "name")}`,
                                value: getFieldValue(el.fields, "id"),
                            } as DefaultOptionType)
                    )
                }),
        initialData: [],
    })

    return (
        <>
            <Form.Item className="fw" label="Валюта входная">
                <Select
                    value={defaultCurrency}
                    options={options}
                    disabled
                    loading={defaultOptionsFetching || defaultCurrencyFetching}
                    size="large"
                />
            </Form.Item>

            <Form.Item
                className="fw"
                label="Валюта экспорт"
                name={"exportCurrency"}
            >
                <Input disabled size="large" />
            </Form.Item>

            <Form.Item label="Курс" className="noMargin">
                <Flex.Row gap={10} className="fw" justify="start">
                    <UNumberItem
                        floatRank={4}
                        useInForm={{
                            name: "inputCurrencyRate",
                            rules: [
                                {
                                    required: true,
                                    message: "Заполните поле",
                                },
                            ],
                        }}
                    />

                    <UNumberItem
                        floatRank={0}
                        useInForm={{
                            name: "inputCurrencyAmount",
                            label: "Кратность",
                            rules: [
                                {
                                    required: true,
                                    message: "Заполните поле",
                                },
                            ],
                        }}
                    />
                </Flex.Row>
            </Form.Item>
        </>
    )
}
