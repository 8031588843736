import ValuesAsyncSelector from "components/QueryBuilder/ValueAsyncSelector"
import { ValueEditorProps } from "react-querybuilder"
import classes from "./NomenclatureFilters.module.sass"

function NomenclatureFiltersValueEditor(props: ValueEditorProps) {
    const { field } = props

    return (
        <div className={classes.valueSelector}>
            <ValuesAsyncSelector
                {...props}
                requestData={{
                    attribute: field,
                }}
                optionUrl="v1/query-builder/price-nomenclature-filter/values-list"
            />
        </div>
    )
}

export default NomenclatureFiltersValueEditor
