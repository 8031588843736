import { Uuid } from "types"
import { GroupSelectState, ProducerSelectState } from "types/interfaces"
import { TableRow } from "types/types"
import { create } from "zustand"

export type PriceMarginPreset = {
    id: Uuid
    order: number
    calculationType: string
}

interface IUseCaclulationRulesStore
    extends ProducerSelectState,
        GroupSelectState {
    nomenclatureFilters: Uuid[]
    setNomenclatureFilters: (v: Uuid[]) => void
    initialNomenclatureFilters: Uuid[]
    setInitialNomenclatureFilters: (v: Uuid[]) => void
    priceCalculationRulePriceMarginPresetLink: PriceMarginPreset[]
    setPriceMarginPresetLink: (v: PriceMarginPreset[]) => void
    initialPriceCalculationRulePriceMarginPresetLink: PriceMarginPreset[]
    setInitialPriceMarginPresetLink: (v: PriceMarginPreset[]) => void
    toggleNomenclatureFilter: (v: TableRow) => void
    togglePriceCalculationRule: (v: TableRow) => void
    onChangeCalculationRuleOrder: (rowId: Uuid, order: number | null) => void
    onChangeDoCalulateFromBasePrice: (rowId: Uuid, value: string) => void
}

export const useCalculationRulesStore = create<IUseCaclulationRulesStore>(
    (set) => ({
        nomenclatureFilters: [],
        initialNomenclatureFilters: [],
        priceCalculationRulePriceMarginPresetLink: [],
        initialPriceCalculationRulePriceMarginPresetLink: [],
        activeProducer: null,
        activeGroup: null,
        toggleNomenclatureFilter(v) {
            return set((state) => {
                const found = state.nomenclatureFilters.includes(v.id)
                return {
                    ...state,
                    nomenclatureFilters: found
                        ? state.nomenclatureFilters.filter((el) => el !== v.id)
                        : [...state.nomenclatureFilters, v.id],
                }
            })
        },
        togglePriceCalculationRule(v) {
            return set((state) => {
                const found =
                    state.priceCalculationRulePriceMarginPresetLink.find(
                        (el) => el.id === v.id
                    )
                return {
                    ...state,
                    priceCalculationRulePriceMarginPresetLink: found
                        ? state.priceCalculationRulePriceMarginPresetLink.filter(
                              (el) => el.id !== v.id
                          )
                        : [
                              ...state.priceCalculationRulePriceMarginPresetLink,
                              { ...v, order: 0 },
                          ],
                }
            })
        },
        setActiveProducer(v) {
            return set((state) => ({
                ...state,
                activeProducer: v,
            }))
        },
        setActiveGroup(v) {
            return set((state) => ({
                ...state,
                activeGroup: v,
            }))
        },
        onChangeCalculationRuleOrder(rowId, order) {
            return set((state) => {
                const rowIndex =
                    state.priceCalculationRulePriceMarginPresetLink.findIndex(
                        (el) => el.id === rowId
                    )
                const newRows = [
                    ...state.priceCalculationRulePriceMarginPresetLink,
                ]
                if (newRows[rowIndex]) {
                    newRows[rowIndex].order = order ?? 0
                }
                return {
                    ...state,
                    priceCalculationRulePriceMarginPresetLink: newRows,
                }
            })
        },
        onChangeDoCalulateFromBasePrice(rowId, value) {
            return set((state) => {
                const rowIndex =
                    state.priceCalculationRulePriceMarginPresetLink.findIndex(
                        (el) => el.id === rowId
                    )
                const newRows = [
                    ...state.priceCalculationRulePriceMarginPresetLink,
                ]
                if (newRows[rowIndex]) {
                    newRows[rowIndex].calculationType = value
                }
                return {
                    ...state,
                    priceCalculationRulePriceMarginPresetLink: newRows,
                }
            })
        },
        setNomenclatureFilters(v) {
            return set((state) => ({
                ...state,
                nomenclatureFilters: v,
            }))
        },
        setInitialNomenclatureFilters(v) {
            return set((state) => ({
                ...state,
                nomenclatureFilters: v,
                initialNomenclatureFilters: v,
            }))
        },
        setPriceMarginPresetLink(v) {
            return set((state) => ({
                ...state,
                priceCalculationRulePriceMarginPresetLink: v,
            }))
        },
        setInitialPriceMarginPresetLink(v) {
            return set((state) => ({
                ...state,
                priceCalculationRulePriceMarginPresetLink: v,
                initialPriceCalculationRulePriceMarginPresetLink: v,
            }))
        },
    })
)
