import { Collapse } from "antd"
import React from "react"
import { ListChildComponentProps, VariableSizeList } from "react-window"

const { Panel } = Collapse

interface VirtualListCollapseProps {
    itemCount?: number
    itemSize?: (index: number) => number
    minHeight?: number
}

export default function UVirtualList<RowData>({
    itemCount,
    itemSize,
    items,
    height,
    width,
    minHeight = 0,
}: VirtualListCollapseProps & {
    height?: number
    width?: number
    items: Array<{ data: RowData; render: (row: RowData) => React.ReactNode }>
}) {
    const Row: any = ({ index, style }: ListChildComponentProps) => {
        return <div style={style}>{items[index].render(items[index].data)}</div>
    }

    return (
        <VariableSizeList
            height={height ? (height > minHeight ? height : minHeight) : 400}
            itemCount={itemCount ?? items.length}
            itemSize={itemSize ? itemSize : () => 48}
            width={width ?? "100%"}
            className="uvirtual-list"
        >
            {Row}
        </VariableSizeList>
    )
}
